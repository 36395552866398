export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [];

export const dictionary = {
		"/": [20],
		"/activation": [21,[2]],
		"/activation/confirmation": [22,[2]],
		"/admin": [23,[3]],
		"/admin/announcement": [24,[3]],
		"/admin/announcement/new": [25,[3]],
		"/admin/announcement/preview": [26,[4]],
		"/admin/announcement/update/[announcementId]": [27,[3]],
		"/admin/users": [28,[3]],
		"/ai": [29,[5]],
		"/ai/competitive-analysis": [30,[5,6]],
		"/ai/document-chat": [31,[5]],
		"/ai/home": [32,[5,7]],
		"/ai/open-chat": [33,[5]],
		"/ai/organization": [34,[5]],
		"/ai/reports": [35,[5,8]],
		"/ai/resources": [36,[5,9]],
		"/ai/sustainability-analysis": [37,[5,10]],
		"/ai/welcome": [38,[5]],
		"/email-verify/[...activationdata]": [39],
		"/platform/view-org": [41,[11]],
		"/platform/[slug]/users/onboarding": [40],
		"/purpose": [42],
		"/purpose/announcement/[announcementId]/[announcementTitleSlug]": [43,[12]],
		"/purpose/campaigns": [48,[12]],
		"/purpose/campaign": [44],
		"/purpose/campaign/[campaignHandle]": [45,[12]],
		"/purpose/campaign/[campaignHandle]/action/[actionType]/[actionHandle]": [46,[12]],
		"/purpose/campaign/[campaignHandle]/learn/[learnHandle]": [47,[12,13]],
		"/purpose/discuss": [49,[12,14]],
		"/purpose/discuss/[handle]": [50,[12,14]],
		"/purpose/impact": [51,[12]],
		"/purpose/learn": [52,[12,15]],
		"/purpose/profile": [53,[12]],
		"/purpose/profile/edit": [54,[12]],
		"/purpose/team": [55,[12]],
		"/purpose/training": [56,[12]],
		"/reset-password": [57,[16]],
		"/sign-in": [58,[17]],
		"/sign-in/password": [59,[17]],
		"/sign-in/sso": [60,[17]],
		"/sign-in/sso/confirmation": [61],
		"/unauthorized": [62],
		"/unsubscribe": [63,[18]],
		"/wellness/[...subroute]": [64,[19]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';